import { combineReducers } from "redux";
import authReducer from "./authReducer";
import gameReducer from "./gameReducer";
import usersReducer from "./usersReducer";
import gameStudioReducer from "./gameStudioReducer";
import usersPermissionReducer from "./usersPermissionReducer";
import userProfileReducer from "./userProfileReducer";
import rewardReducer from "./rewardRedicer";
const rootReducer = combineReducers({
  auth: authReducer,
  game: gameReducer,
  users: usersReducer,
  gameStudio: gameStudioReducer,
  userPermission: usersPermissionReducer,
  userProfile: userProfileReducer,
  reward:rewardReducer
});

export default rootReducer;
